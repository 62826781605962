import * as React from 'react';

import * as colors from 'libs/colors';
import Divider from 'components/atoms/Divider';
import SearchInput from 'components/molecules/SearchInput';
import type {
  Coupon,
  SubOptionCategory,
  Age,
  Detail,
} from 'components/combi-search/types';
import { availableAges } from 'components/combi-search/libs/ages';
import { CombiSearchContext } from 'components/combi-search/Context';

import cs from './index.scss';
import SelectAgeItem from '../molecules/SelectAgeItem';
import CheckBoxItem from '../molecules/CheckBoxItem';
import Box from 'components/atoms/Box';
import { HelpCircle } from 'components/icons';
import OutsideClickHandler from 'components/atoms/OutsideClickHandler';

type Props = {
  selected: Detail;
  onSelect: (detail: Detail) => void;
};

const DetailSelector: React.FC<Props> = React.memo(({ selected, onSelect }) => {
  const { coupons, subOptionCategories } = React.useContext(CombiSearchContext);

  return (
    <div className={cs['container']}>
      <KeywordSection
        value={selected.keyword}
        onChange={React.useCallback(
          (keyword) => onSelect({ ...selected, keyword }),
          [onSelect, selected]
        )}
      />
      <Divider bg={colors.gray20} />
      <AgeSection
        age={selected.age}
        available={availableAges}
        onSelect={React.useCallback((age) => onSelect({ ...selected, age }), [
          onSelect,
          selected,
        ])}
      />
      <Divider bg={colors.gray20} />
      <DeliverySection
        express={selected.express}
        onChange={React.useCallback(
          (express) => onSelect({ ...selected, express }),
          [onSelect, selected]
        )}
      />
      <Divider bg={colors.gray20} />
      <SubOptionSection
        available={subOptionCategories}
        selected={selected.subOptionCategories}
        onChange={React.useCallback(
          (subOptionCategories) =>
            onSelect({ ...selected, subOptionCategories }),
          [onSelect, selected]
        )}
      />
      <Divider bg={colors.gray20} />
      <CouponSection
        available={coupons}
        selected={selected.coupons}
        onChange={React.useCallback(
          (coupons) => onSelect({ ...selected, coupons }),
          [onSelect, selected]
        )}
      />
      <Divider bg={colors.gray20} />
      <ExtraOptionSection selected={selected} onSelect={onSelect} />
    </div>
  );
});

DetailSelector.displayName = 'DetailSelector';

type KeywordSectionProps = {
  value: string;
  onChange: (keyword: string) => void;
};

const KeywordSection: React.FC<KeywordSectionProps> = React.memo(
  ({ value, onChange }) => (
    <div className={cs['section-container-p16']}>
      <div className={cs['section-title']}>キーワード</div>
      <SearchInput
        value={value}
        onChange={onChange}
        placeholder="キーワード・商品名を検索"
        wide
        noBorder
        noFocus
      />
    </div>
  )
);

KeywordSection.displayName = 'KeywordSection';

type AgeSectionProps = {
  available: Age[];
  age: Age | null;
  onSelect: (age: Age) => void;
};

const AgeSection: React.FC<AgeSectionProps> = React.memo(
  ({ available, age, onSelect }) => (
    <div className={cs['section-container-p16']}>
      <div className={cs['section-title']}>年代</div>
      <SelectAgeItem
        availables={available}
        selected={age}
        onSelect={onSelect}
      />
    </div>
  )
);

AgeSection.displayName = 'AgeSection';

type DeliverySectionProps = {
  express: boolean;
  onChange: (delivery: boolean) => void;
};

const DeliverySection: React.FC<DeliverySectionProps> = React.memo(
  ({ express, onChange }) => (
    <div className={cs['section-container-p7']}>
      <div className={cs['section-title']}>配送</div>
      <CheckBoxItem
        width="190px"
        label="お急ぎ便"
        checked={express}
        onChange={(checked) => {
          const newSelected = checked ? true : false;
          onChange(newSelected);
        }}
      />
    </div>
  )
);

DeliverySection.displayName = 'DeliverySection';

type SubOptionSectionProps = {
  available: SubOptionCategory[];
  selected: SubOptionCategory[];
  onChange: (selected: SubOptionCategory[]) => void;
};

const SubOptionSection: React.FC<SubOptionSectionProps> = React.memo(
  ({ available, selected, onChange }) => (
    <div className={cs['section-container-p7']}>
      <div className={cs['section-title']}>オプション</div>
      <div className={cs['option-labels']}>
        {available.map((subOptionCategory) => (
          <CheckBoxItem
            width="190px"
            label={subOptionCategory.name}
            checked={selected.some((o) => o.id === subOptionCategory.id)}
            onChange={(checked) => {
              const newSelected = checked
                ? selected.concat([subOptionCategory])
                : selected.filter((o) => o.id !== subOptionCategory.id);
              onChange(newSelected);
            }}
            key={subOptionCategory.id}
          />
        ))}
      </div>
    </div>
  )
);

SubOptionSection.displayName = 'SubOptionSection';

type CouponSectionProps = {
  available: Coupon[];
  selected: Coupon[];
  onChange: (coupon: Coupon[]) => void;
};

const CouponSection: React.FC<CouponSectionProps> = React.memo(
  ({ available, selected, onChange }) => (
    <div className={cs['section-container-p7']}>
      <div className={cs['section-title']}>クーポン</div>
      <div className={cs['option-labels']}>
        {available.map((coupon) => (
          <CheckBoxItem
            width="405px"
            label={coupon.name}
            checked={selected.some((o) => o.id === coupon.id)}
            onChange={(checked) => {
              const newSelected = checked
                ? selected.concat([coupon])
                : selected.filter((o) => o.id !== coupon.id);
              onChange(newSelected);
            }}
            key={coupon.id}
          />
        ))}
      </div>
    </div>
  )
);

CouponSection.displayName = 'CouponSection';

/*
 * ===================
 * ExtraOptionSection
 * ===================
 */
type ExtraOptionSectionProps = {
  selected: Detail;
  onSelect: (detail: Detail) => void;
};

const ExtraOptionSection: React.FC<ExtraOptionSectionProps> = React.memo(
  ({ selected, onSelect }) => (
    <div className={cs['section-container-p7']}>
      <div className={cs['section-title']}>対象商品</div>
      <div className={cs['option-labels']}>
        <CheckBoxItem
          width="405px"
          label="eギフト対象商品のみ表示"
          checked={selected.onlyIncludeEGiftProducts}
          onChange={React.useCallback(
            (onlyIncludeEGiftProducts) =>
              onSelect({ ...selected, onlyIncludeEGiftProducts }),
            [onSelect, selected]
          )}
        />
        <CheckBoxItem
          width="405px"
          label="在庫切れ商品も表示"
          checked={selected.includeOutOfStock}
          onChange={React.useCallback(
            (includeOutOfStock) => onSelect({ ...selected, includeOutOfStock }),
            [onSelect, selected]
          )}
        />
        <TanpProductsOnlyCheckbox selected={selected} onSelect={onSelect} />
      </div>
    </div>
  )
);

ExtraOptionSection.displayName = 'ExtraOptionSection';

/*
 * =========================
 * TanpProductsOnlyCheckbox
 * =========================
 */
type TanpProductsOnlyCheckbox = {
  selected: Detail;
  onSelect: (detail: Detail) => void;
};

const TanpProductsOnlyCheckbox: React.FC<TanpProductsOnlyCheckbox> = React.memo(
  ({ selected, onSelect }) => {
    const [showHelpDialogue, setShowHelpDialogue] = React.useState<boolean>(
      false
    );
    const openHelpDialogue = React.useCallback(() => {
      setShowHelpDialogue(true);
    }, []);
    const closeHelpDialogue = React.useCallback(() => {
      setShowHelpDialogue(false);
    }, []);

    return (
      <div className={cs['extra-option']}>
        <Box horizontal align="center">
          {showHelpDialogue ? (
            <OutsideClickHandler onClickOutside={closeHelpDialogue}>
              <div className={cs['extra-option-bubble-box']}>
                <div className={cs['extra-option-bubble-card']}>
                  <p className={cs['extra-option-bubble-text']}>
                    タンプ倉庫から発送する商品で、多彩なオプションがお選び頂けます。なお、発送元が同一の商品に対して、注文個数に応じた追加の送料がかかることはございません。
                  </p>
                </div>
              </div>
            </OutsideClickHandler>
          ) : null}
          <CheckBoxItem
            width="100%"
            label="タンプからの発送商品のみ表示"
            checked={selected.isTanpProducts}
            onChange={React.useCallback(
              (isTanpProducts) => onSelect({ ...selected, isTanpProducts }),
              [onSelect, selected]
            )}
          />
          <div
            className={cs['extra-option-help-circle']}
            onMouseOver={openHelpDialogue}
            onMouseLeave={closeHelpDialogue}
          >
            <HelpCircle size="14.5px" color={colors.gray50} />
          </div>
        </Box>
      </div>
    );
  }
);

TanpProductsOnlyCheckbox.displayName = 'TanpProductsOnlyCheckbox';

export default DetailSelector;
