// # Returns
// string, e.g. "¥ 1,234"
export const fmtPrice = (price: number): string => priceFormatter.format(price);

const priceFormatter = new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY',
});

// # Returns
// string, e.g. "1,234"
export const fmtNum = (num: number): string => numFormatter.format(num);

const numFormatter = new Intl.NumberFormat('ja-JP');
