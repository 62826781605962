import * as React from 'react';

import cs from './index.scss';

const Header: React.FC<{ title: string }> = React.memo(({ title }) => (
  <div className={cs['header']}>{title}</div>
));

Header.displayName = 'Header';

export default Header;
