import type {
  CombiSearchData,
  Relationship,
  Scene,
  ParentCategory,
  DeliveryCondition,
  PreciousDay,
  Coupon,
  SubOptionCategory,
} from 'components/combi-search/types';

export const loadDataFromDom = (dataDomId: string): CombiSearchData => {
  const dataDom = document.getElementById(dataDomId);
  if (!dataDom) throw new Error(`data dom ${dataDomId} is not found`);

  const dataTxt = dataDom.textContent || '';
  const rawData = JSON.parse(dataTxt) as RawData;

  return convertRawData(rawData);
};

const convertRawData = (data: RawData): CombiSearchData => {
  const deliveryConditions = data.minDeliveryDays.map(parseMinDeliveryDay);

  const preciousDays = data.preciousDays
    .map(parsePreciousDay)
    .concat(data.preciousDayScenes.map(parsePreciousDayScene));

  const hasteShippingTime = parseHasteShippingTime(data.hasteShippingTime);

  return {
    scenes: data.scenes,
    relationships: data.relationships,
    parents: data.parents,
    deliveryConditions,
    preciousDays,
    hasteShippingTime,
    coupons: data.coupons,
    subOptionCategories: data.subOptionCategories,
  };
};

const parseMinDeliveryDay = (row: MinDeliveryDay): DeliveryCondition => {
  const prefecture = {
    id: row.prefectureId,
    name: row.prefectureName,
  };

  const minDeliveryDate = new Date(
    row.normalYear,
    row.normalMonth - 1,
    row.normalDay
  );

  let hasteShippingDate = null;
  if (
    row.hasteYear !== null &&
    row.hasteMonth !== null &&
    row.hasteDay !== null
  ) {
    hasteShippingDate = new Date(
      row.hasteYear,
      row.hasteMonth - 1,
      row.hasteDay
    );
  }

  return {
    prefecture,
    minDeliveryDate,
    hasteShippingDate,
  };
};

const parseHasteShippingTime = (
  row: HasteShippingTime
): { start: Date; end: Date } => {
  const start = new Date(
    parseInt(row.startYear),
    parseInt(row.startMonth) - 1,
    parseInt(row.startDay),
    parseInt(row.startHour)
  );
  const end = new Date(
    parseInt(row.endYear),
    parseInt(row.endMonth) - 1,
    parseInt(row.endDay),
    parseInt(row.endHour)
  );

  return {
    start,
    end,
  };
};

const parsePreciousDay = (row: RawPreciousDay): PreciousDay => ({
  month: row.month,
  day: row.day,
  event: {
    type: 'user',
    personName: row.personName,
    eventName: row.sceneName,
    imageUrl: row.personImageUrl,
  },
});

const parsePreciousDayScene = (row: PreciousDayScene): PreciousDay => ({
  month: row.month,
  day: row.day,
  event: {
    type: 'general',
    eventName: row.name,
    engName: row.engName,
    imageUrl: row.iconUrl,
  },
});

type RawData = {
  scenes: Scene[];
  relationships: Relationship[];
  parents: ParentCategory[];
  prefectures: Prefecture[];
  preciousDays: RawPreciousDay[];
  preciousDayScenes: PreciousDayScene[];
  hasteShippingTime: HasteShippingTime;
  minDeliveryDays: MinDeliveryDay[];
  coupons: Coupon[];
  subOptionCategories: SubOptionCategory[];
};

type Prefecture = {
  id: number;
  name: string;
  engName: string;
};

type RawPreciousDay = {
  year: number | null;
  month: number;
  day: number;
  personName: string;
  personImageUrl: string;
  sceneEngName: string;
  sceneName: string;
};

type PreciousDayScene = {
  year: number | null;
  month: number;
  day: number;
  iconUrl: string;
  engName: string;
  name: string;
};

type HasteShippingTime = {
  startYear: string;
  startMonth: string;
  startDay: string;
  startHour: string;
  endYear: string;
  endMonth: string;
  endDay: string;
  endHour: string;
};

type MinDeliveryDay = {
  prefectureId: number;
  prefectureName: string;
  prefectureEngName: string;
  normalYear: number;
  normalMonth: number;
  normalDay: number;
  hasteYear: number | null;
  hasteMonth: number | null;
  hasteDay: number | null;
};
