import * as React from 'react';

import * as colors from 'libs/colors';
import Divider from 'components/atoms/Divider';
import type { Scene } from 'components/combi-search/types';
import Header from 'components/combi-search/pc/components/Header';
import Footer from 'components/combi-search/pc/components/Footer';
import SceneSelector from 'components/combi-search/pc/components/SceneSelector';

import Dropdown from './Dropdown';

type Props = {
  show: boolean;
  onClose: () => void;
  onSelect: (scene: Scene | null) => void;
};

const SceneSelectDropdown: React.FC<Props> = React.memo(
  ({ show, onClose, onSelect }) => {
    const [scene, setScene] = React.useState<Scene | null>(null);

    const onSceneSelect = React.useCallback(
      (scene) => {
        setScene(scene);
        onSelect(scene);
      },
      [onSelect]
    );

    const onSubmit = React.useCallback(() => {
      onClose();
      onSelect(scene);
    }, [onClose, onSelect, scene]);

    const onClear = React.useCallback(() => {
      setScene(null);
      onSelect(null);
    }, [onSelect]);

    const onClickOutside = React.useCallback(() => {
      onClose();
      onSelect(scene);
    }, [onClose, onSelect, scene]);

    if (!show) {
      return null;
    } else {
      return (
        <Dropdown onClickOutside={onClickOutside}>
          <Header title="シーン" />
          <SceneSelector selectedScene={scene} onSelect={onSceneSelect} />
          <Divider bg={colors.gray20} />
          <Footer onSubmit={onSubmit} onClear={onClear} />
        </Dropdown>
      );
    }
  }
);

SceneSelectDropdown.displayName = 'SceneSelectDropdown';

export default SceneSelectDropdown;
