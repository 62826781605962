import * as React from 'react';

import type { CombiSearchData } from 'components/combi-search/types';

export const EMPTY_DATA: CombiSearchData = {
  scenes: [],
  relationships: [],
  parents: [],
  deliveryConditions: [],
  preciousDays: [],
  hasteShippingTime: {
    start: new Date(0),
    end: new Date(0),
  },
  coupons: [],
  subOptionCategories: [],
};

export const CombiSearchContext = React.createContext<CombiSearchData>(
  EMPTY_DATA
);

export type CombiSearchContextProviderProps = {
  value: CombiSearchData | null;
};

export const CombiSearchContextProvider: React.FC<CombiSearchContextProviderProps> = ({
  value,
  children,
}) => (
  <CombiSearchContext.Provider value={value ?? EMPTY_DATA}>
    {children}
  </CombiSearchContext.Provider>
);
