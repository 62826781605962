import * as React from 'react';

import * as colors from 'libs/colors';
import Divider from 'components/atoms/Divider';
import type { PriceRange } from 'components/combi-search/types';
import PriceRangeSelector from 'components/combi-search/pc/components/PriceRangeSelector';
import Footer from 'components/combi-search/pc/components/Footer';

import Dropdown from '../Dropdown';
import cs from './index.scss';

type Props = {
  show: boolean;
  onClose: () => void;
  onSelect: (priceRange: PriceRange) => void;
};

const PriceRangeSelectDropdown: React.FC<Props> = React.memo(
  ({ show, onClose, onSelect }) => {
    const [selected, setSelected] = React.useState<PriceRange>(initPriceRange);

    const onPriceChange = React.useCallback(
      (selected) => {
        setSelected(selected);
        onSelect(selected);
      },
      [onSelect]
    );

    const onClear = React.useCallback(() => {
      setSelected(initPriceRange);
      onSelect(initPriceRange);
    }, [onSelect]);

    const onClickOutside = React.useCallback(() => {
      onClose();
      onSelect(selected);
    }, [onClose, onSelect, selected]);

    const onSubmit = React.useCallback(() => {
      onClose();
      onSelect(selected);
    }, [onClose, onSelect, selected]);

    if (!show) {
      return null;
    } else {
      return (
        <Dropdown onClickOutside={onClickOutside} className={cs['position']}>
          <PriceRangeSelector
            priceRange={selected}
            maxPrice={30000}
            step={1000}
            minRange={1000}
            onChange={onPriceChange}
          />
          <Divider bg={colors.gray20} />
          <Footer onSubmit={onSubmit} onClear={onClear} />
        </Dropdown>
      );
    }
  }
);

PriceRangeSelectDropdown.displayName = 'PriceRangeSelectDropdown';

const initPriceRange = {
  lower: 0,
  upper: null,
};

export default PriceRangeSelectDropdown;
