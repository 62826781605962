import * as React from 'react';
import classes from './Paragraph.scss';
import { Color } from 'libs/colors';

export type Props = {
  // if true, set width 100%.
  full?: boolean;
  // 文字の切り詰めを行いたい時はnowrapを指定しない
  nowrap?: boolean;
  align?: 'left' | 'center' | 'right';
  // なんらかの値がセットされた場合、その行数に収まるように
  // 文字を切り詰める
  maxLines?: number;
  lineHeight?: number;
  size?: string;
  color?: Color;
  bold?: boolean;
  className?: string;
};

/*
 * 1つのParagraph中で、複数のtext styleを
 * 使いたい時にはTextを使用する
 */
const Paragraph: React.FC<Props> = React.memo(
  ({
    full,
    nowrap,
    align,
    maxLines,
    lineHeight,
    size,
    color,
    bold,
    className,
    children,
  }) => {
    const classNames = [];
    if (full) classNames.push(classes['full']);
    if (nowrap) classNames.push(classes['nowrap']);
    if (maxLines) classNames.push(classes['line-clamp']);
    if (bold) classNames.push(classes['bold']);
    if (className) classNames.push(className);

    const style: React.CSSProperties = {
      textAlign: align,
      lineHeight: lineHeight || 1.4,
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      ['--max-lines' as any]: maxLines,
    };
    if (size) style.fontSize = size;
    if (color) style.color = color.hex;

    return (
      <p className={classNames.join(' ')} style={style}>
        {children}
      </p>
    );
  }
);

Paragraph.displayName = 'Paragraph';

export default Paragraph;
