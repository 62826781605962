import * as React from 'react';

export const useIsSsr = (): boolean => {
  const [isSsr, setIsSsr] = React.useState(true);

  React.useEffect(() => {
    setIsSsr(false);
  }, []);

  return isSsr;
};
