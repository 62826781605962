import * as React from 'react';

import cs from './index.scss';

type SelectCircleProps = {
  isSelected: boolean;
};

const SelectCircle: React.FC<SelectCircleProps> = React.memo(
  ({ isSelected }) => (
    <div className={isSelected ? cs['selected'] : cs['unselected']}></div>
  )
);

SelectCircle.displayName = 'SelectCircle';

export default SelectCircle;
