import * as React from 'react';

import cs from './index.scss';

type Props = {
  onClickOutside: () => void;
  className?: string;
};

const Dropdown: React.FC<Props> = React.memo((props) => {
  // widthやleft、z-indexなどはclassNameとして渡す
  const classNames = [cs['dropdown']];
  if (props.className) classNames.push(props.className);
  const className = classNames.join(' ');

  // ドロップダウン外をクリックしたときにドロップダウンを閉じる処理
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener('click', handleOutSideClick);
    return () => {
      document.removeEventListener('click', handleOutSideClick);
    };
  });

  const handleOutSideClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.onClickOutside();
    }
  };

  return (
    <div ref={ref} className={className}>
      <div className={cs['container']}>{props.children}</div>
    </div>
  );
});

Dropdown.displayName = 'Dropdown';

export default Dropdown;
