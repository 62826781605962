import * as React from 'react';
import { gray20, Color } from 'libs/colors';

export type Props = {
  bg?: Color;
  thickness?: string; // 線の太さ
  length?: string; // 線の長さ
  vertical?: boolean;
};

const Divider: React.FC<Props> = React.memo(
  ({ bg, thickness = '1px', length = '100%', vertical, children }) => {
    const width = vertical ? thickness : length;
    const height = vertical ? length : thickness;

    const style = {
      width,
      minWidth: width,
      maxWidth: width,
      height,
      minHeight: height,
      maxHeight: height,
      backgroundColor: (bg ?? gray20).hex,
    };

    return <div style={style}>{children}</div>;
  }
);
Divider.displayName = 'Divider';

export default Divider;
