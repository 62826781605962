import * as React from 'react';

import Box from 'components/atoms/Box';
import Spacer from 'components/atoms/Spacer';
import CheckBox from 'components/atoms/CheckBox';
import Paragraph from 'components/atoms/Paragraph';
import * as colors from 'libs/colors';

type CheckBoxItemProps = {
  width: string;
  label: string;
  checked: boolean;
  onChange: (val: boolean) => void;
};

const CheckBoxItem: React.FC<CheckBoxItemProps> = React.memo(
  ({ width, label, checked, onChange }) => (
    <label>
      <Box horizontal width={width} padding="9px 26px 9px 0px">
        <CheckBox checked={checked} onChange={onChange} />
        <Spacer size={10} />
        <Paragraph align="center" size="15px" color={colors.gray90}>
          {label}
        </Paragraph>
      </Box>
    </label>
  )
);

CheckBoxItem.displayName = 'CheckBoxItem';

export default CheckBoxItem;
