import * as React from 'react';

import cs from './index.scss';

type Props = {
  onSubmit: () => void;
  onClear: () => void;
};

const Footer: React.FC<Props> = React.memo(({ onSubmit, onClear }) => (
  <div className={cs['footer_outer']}>
    <div className={cs['footer_inner']}>
      <div className={cs['clear_button']} onClick={onClear}>
        クリア
      </div>
      <div className={cs['save_button']} onClick={onSubmit}>
        保存
      </div>
    </div>
  </div>
));

Footer.displayName = 'Footer';

export default Footer;
