import * as React from 'react';

import type { CombiSearchData } from 'components/combi-search/types';
import { CombiSearchContextProvider } from 'components/combi-search/Context';
import {
  SearchConditions,
  fetchItemCount,
  queryToString,
  searchConditionToURL,
} from 'components/combi-search/libs/search';
import { loadDataFromDom } from 'components/combi-search/libs/loadDataFromDom';
import CombiSearchForm from './CombiSearchForm';

const PreBuildableCombiSearchForm: React.FC = () => {
  const [
    combiSearchData,
    setCombiSearchData,
  ] = React.useState<CombiSearchData | null>(null);

  React.useEffect(() => {
    const data = loadDataFromDom('top-page-data');
    setCombiSearchData(data);
  }, []);

  const onSubmit = React.useCallback((cond: SearchConditions) => {
    const { path, query } = searchConditionToURL(cond);
    window.location.href = path + queryToString(query);
  }, []);

  return (
    <CombiSearchContextProvider value={combiSearchData}>
      <CombiSearchForm fetchItemCount={fetchItemCount} onSubmit={onSubmit} />
    </CombiSearchContextProvider>
  );
};

export default PreBuildableCombiSearchForm;
