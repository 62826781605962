import * as React from 'react';

import { fmtNum } from 'libs/formatter';
import { useIsSsr } from 'libs/useIsSsr';
import Divider from 'components/atoms/Divider';
import Spacer from 'components/atoms/Spacer';
import type {
  Scene,
  Relationship,
  SelectedCategory,
  PriceRange,
  Detail,
} from 'components/combi-search/types';
import {
  SearchConditions,
  emptySearchConditions,
} from 'components/combi-search/libs/search';

import cs from './index.scss';
import DropdownSwitcher, { DropdownType } from './DropdownSwitcher';
import {
  InputContainer,
  InputTitle,
  InputItem,
} from './molecules/ConditionInput';

type Props = {
  fetchItemCount: (selected: SearchConditions) => Promise<number>;
  onSubmit: (cond: SearchConditions) => void;
};

const CombiSearchForm: React.FC<Props> = ({ fetchItemCount, onSubmit }) => {
  const [selected, setSelected] = React.useState<SearchConditions>(
    emptySearchConditions
  );

  const [dropdown, setDropdown] = React.useState<DropdownType | null>(null);
  const closeDropdown = React.useCallback(() => {
    setDropdown(null);
  }, []);

  const [itemCount, setItemCount] = React.useState<number | null>(null);
  React.useEffect(() => {
    fetchItemCount(selected)
      .then(setItemCount)
      .catch(() => setItemCount(null));
  }, [fetchItemCount, selected]);

  const onSubmitClick = React.useCallback(() => {
    onSubmit(selected);
  }, [onSubmit, selected]);

  const isSsr = useIsSsr();

  return (
    <>
      <div className={cs['search_form_outer']}>
        <div className={cs['input_container']}>
          <SceneInput
            isColored={dropdown === 'scene'}
            selected={selected.scene}
            onClick={() => {
              setDropdown('scene');
            }}
          />
          <Divider vertical length="45px" />
          <RelationshipInput
            isColored={dropdown === 'relationship'}
            selected={selected.relationship}
            onClick={() => {
              setDropdown('relationship');
            }}
          />
          <Divider vertical length="45px" />
          <CategoryInput
            isColored={dropdown === 'category'}
            selected={selected.category}
            onClick={() => {
              setDropdown('category');
            }}
          />
          <Divider vertical length="45px" />
          <PriceRangeInput
            isColored={dropdown === 'price-range'}
            selected={selected.priceRange}
            onClick={() => {
              setDropdown('price-range');
            }}
          />
          <Divider vertical length="45px" />
          <DetailInput
            isColored={dropdown === 'detail'}
            selected={selected.detail}
            onClick={() => {
              setDropdown('detail');
            }}
          />
          <SearchButton itemCount={itemCount} onSubmitClick={onSubmitClick} />
        </div>
      </div>

      {/* 事前ビルド時にはレンダリングしない */}
      {isSsr ? null : (
        <DropdownSwitcher
          dropdown={dropdown}
          selected={selected}
          onClose={closeDropdown}
          onSelect={setSelected}
        />
      )}
    </>
  );
};

CombiSearchForm.displayName = 'CombiSearchForm';

type SceneInputProps = {
  isColored: boolean;
  selected: Scene | null;
  onClick: () => void;
};

const SceneInput: React.FC<SceneInputProps> = React.memo(
  ({ isColored, selected, onClick }) => (
    <InputContainer isColored={isColored} onClick={onClick}>
      <InputTitle title="シーン" isColored={isColored} />
      <Spacer size={8} />
      <InputItem isSelected={selected !== null}>
        {selected?.name ?? 'シーンを選択'}
      </InputItem>
    </InputContainer>
  )
);

SceneInput.displayName = 'SceneInput';

type RelationshipInputProps = {
  isColored: boolean;
  selected: Relationship | null;
  onClick: () => void;
};

const RelationshipInput: React.FC<RelationshipInputProps> = React.memo(
  ({ isColored, selected, onClick }) => (
    <InputContainer isColored={isColored} onClick={onClick}>
      <InputTitle title="関係性" isColored={isColored} />
      <Spacer size={8} />
      <InputItem isSelected={selected !== null}>
        {selected?.name ?? '関係性を選択'}
      </InputItem>
    </InputContainer>
  )
);

RelationshipInput.displayName = 'RelationshipInput';

type CategoryInputProps = {
  isColored: boolean;
  selected: SelectedCategory | null;
  onClick: () => void;
};

const CategoryInput: React.FC<CategoryInputProps> = React.memo(
  ({ isColored, selected, onClick }) => {
    let text = '';
    if (selected?.parent.name) {
      text += selected.parent.name;
    }
    if (selected?.category?.name) {
      text += `・${selected.category.name}`;
    }
    if (selected?.productGroup?.name) {
      text += `・${selected.productGroup.name}`;
    }
    if (selected === null) {
      text = 'カテゴリを選択';
    }

    return (
      <InputContainer isColored={isColored} onClick={onClick}>
        <InputTitle title="カテゴリ" isColored={isColored} />
        <Spacer size={8} />
        <InputItem isSelected={selected !== null}>{text}</InputItem>
      </InputContainer>
    );
  }
);

CategoryInput.displayName = 'CategoryInput';

type PriceRangeInputProps = {
  isColored: boolean;
  selected: PriceRange;
  onClick: () => void;
};

const PriceRangeInput: React.FC<PriceRangeInputProps> = React.memo(
  ({ isColored, selected, onClick }) => {
    const isActive = selected.lower !== 0 || selected.upper !== null;
    const text = React.useMemo(() => {
      const lower = fmtNum(selected.lower);
      const upper = selected.upper ? fmtNum(selected.upper) : '上限なし';
      return `${lower}~${upper}`;
    }, [selected]);

    return (
      <InputContainer isColored={isColored} onClick={onClick}>
        <InputTitle title="価格" isColored={isColored} />
        <Spacer size={8} />
        <InputItem isSelected={isActive}>{text}</InputItem>
      </InputContainer>
    );
  }
);

PriceRangeInput.displayName = 'PriceRangeInput';

type DetailInputProps = {
  isColored: boolean;
  selected: Detail;
  onClick: () => void;
};

const DetailInput: React.FC<DetailInputProps> = React.memo(
  ({ isColored, selected, onClick }) => {
    const textItems = [];
    if (selected.keyword) {
      textItems.push(`"${selected.keyword}"`);
    }
    if (selected.express) {
      textItems.push('お急ぎ便');
    }
    if (selected.age) {
      textItems.push(selected.age.name);
    }
    selected.subOptionCategories.forEach((opt) => {
      textItems.push(opt.name);
    });
    selected.coupons.forEach((coupon) => {
      textItems.push(coupon.name);
    });
    if (selected.includeOutOfStock) {
      textItems.push('在庫切れ商品も表示');
    }
    if (selected.isTanpProducts) {
      textItems.push('タンプからの発送商品のみ表示');
    }
    if (selected.onlyIncludeEGiftProducts) {
      textItems.push('eギフト対象商品のみ表示');
    }

    return (
      <InputContainer isColored={isColored} onClick={onClick}>
        <InputTitle title="こだわり条件" isColored={isColored} />
        <Spacer size={8} />
        <InputItem isSelected={textItems.length !== 0}>
          {textItems.length !== 0 ? (
            <div>
              こだわり（
              <span
                className={cs['detail-number']}
              >{`${textItems.length}`}</span>
              ）
            </div>
          ) : (
            <div>こだわりを選択</div>
          )}
        </InputItem>
      </InputContainer>
    );
  }
);

DetailInput.displayName = 'DetailInput';

type SearchButtonProps = {
  itemCount: number | null;
  onSubmitClick: () => void;
};

const SearchButton: React.FC<SearchButtonProps> = React.memo(
  ({ itemCount, onSubmitClick }) => (
    <div className={cs['search_button_outer']} onClick={onSubmitClick}>
      <div className={cs['search_button_inner']}>
        <div className={cs['button_label']}>詳しく探す</div>
        <Spacer size={7} />
        <div className={cs['button_number']}>
          {`${itemCount !== null ? itemCount : '- '}件`}
        </div>
      </div>
    </div>
  )
);

SearchButton.displayName = 'SearchButton';

export default CombiSearchForm;
