import { Age } from '../types';

export const availableAges: Age[] = [
  {
    name: '10代',
    engName: 'teenager',
  },
  {
    name: '20代前半',
    engName: 'early-20s',
  },
  {
    name: '20代後半',
    engName: 'late-20s',
  },
  {
    name: '30代',
    engName: '30s',
  },
  {
    name: '40代',
    engName: '40s',
  },
  {
    name: '50代',
    engName: '50s',
  },
  {
    name: '60代',
    engName: '60s',
  },
  {
    name: '70代',
    engName: '70s',
  },
  {
    name: '80代',
    engName: '80s',
  },
  {
    name: '90代',
    engName: '90s',
  },
  {
    name: '0-1歳',
    engName: 'under-1years-old',
  },
  {
    name: '2歳',
    engName: '2years-old',
  },
  {
    name: '3歳',
    engName: '3years-old',
  },
  {
    name: '4歳',
    engName: '4years-old',
  },
  {
    name: '5歳',
    engName: '5years-old',
  },
  {
    name: '6-9歳',
    engName: '6-9years-old',
  },
];
