import * as React from 'react';

import * as color from 'libs/colors';
import { merge } from 'libs/classname';
import { SearchIcon } from 'components/icons';

import classes from './SearchInput.scss';

export type Props = {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  wide?: boolean;
  noBorder?: boolean;
  noFocus?: boolean;
};

const SearchInput: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  wide,
  noBorder,
  noFocus,
}) => (
  <div className={merge(classes['container'], wide ? classes['wide'] : null)}>
    <div className={classes['icon-container']}>
      <SearchIcon color={color.gray30} size="18px" />
    </div>
    <input
      className={merge(
        classes['input'],
        noBorder ? classes['no-border'] : null,
        noFocus ? classes['no-focus'] : null
      )}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

export default SearchInput;
