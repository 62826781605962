import * as React from 'react';
import classes from './CheckBox.scss';

export type Props = {
  checked: boolean;
  onChange: (val: boolean) => void;
};

const CheckBox: React.FC<Props> = React.memo(({ checked, onChange }) => (
  <label>
    <input
      className={classes['dummy-input']}
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
    />
    <div className={classes['check-box']}>
      <svg
        width="12"
        height="9"
        viewBox="0 0 12 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9142 0.292969C11.3047 0.683493 11.3047 1.31666 10.9142 1.70718L4.70711 8.20718C4.31658 8.5977 3.68342 8.5977 3.29289 8.20718L0.292893 5.20718C-0.0976311 4.81666 -0.0976311 4.18349 0.292893 3.79297C0.683417 3.40245 1.31658 3.40245 1.70711 3.79297L4 6.08586L9.5 0.292969C9.89052 -0.0975556 10.5237 -0.0975556 10.9142 0.292969Z"
          fill="white"
        />
      </svg>
    </div>
  </label>
));
CheckBox.displayName = 'CheckBox';

export default CheckBox;
