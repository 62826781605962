import * as React from 'react';

import * as colors from 'libs/colors';
import Divider from 'components/atoms/Divider';
import Footer from 'components/combi-search/pc/components/Footer';
import DetailSelector from 'components/combi-search/pc/components/DetailSelector';
import type { Detail } from 'components/combi-search/types';
import { emptySearchConditions } from 'components/combi-search/libs/search';

import Dropdown from './Dropdown';

type Props = {
  show: boolean;
  onClose: () => void;
  onSelect: (detail: Detail) => void;
};

const DetailSelectDropdown: React.FC<Props> = React.memo(
  ({ show, onClose, onSelect }) => {
    const [selected, setSelected] = React.useState<Detail>(
      emptySearchConditions.detail
    );

    const onDetailSelect = React.useCallback(
      (selected) => {
        setSelected(selected);
        onSelect(selected);
      },
      [onSelect]
    );

    const onSubmit = React.useCallback(() => {
      onClose();
      onSelect(selected);
    }, [onClose, onSelect, selected]);

    const onClear = React.useCallback(() => {
      setSelected(emptySearchConditions.detail);
      onSelect(emptySearchConditions.detail);
    }, [onSelect]);

    const onClickOutside = React.useCallback(() => {
      onClose();
      onSelect(selected);
    }, [onClose, onSelect, selected]);

    if (!show) {
      return null;
    } else {
      return (
        <Dropdown onClickOutside={onClickOutside}>
          <DetailSelector selected={selected} onSelect={onDetailSelect} />
          <Divider bg={colors.gray20} />
          <Footer onSubmit={onSubmit} onClear={onClear} />
        </Dropdown>
      );
    }
  }
);

DetailSelectDropdown.displayName = 'DetailSelectDropdown';

export default DetailSelectDropdown;
