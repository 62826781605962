import * as React from 'react';
import ReactDOM from 'react-dom';

import { runAfterDomLoad } from 'libs/runAfterDomLoad';
import PreBuildableCombiSearchForm from 'components/combi-search/pc/top-page/PreBuildableCombiSearchForm';

runAfterDomLoad(() => {
  ReactDOM.hydrate(
    <PreBuildableCombiSearchForm />,
    document.getElementById('react_combi_search_form')
  );
});
